import { supabase } from '../supabase';
import type { User } from '../../stores/auth';

export async function login(email: string, password: string): Promise<User> {
  try {
    const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    if (authError) {
      throw new Error('Usuario o contraseña incorrectos');
    }

    if (!authData.user) {
      throw new Error('No se encontraron datos del usuario');
    }

    // Get user data from public.users table
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('*')
      .eq('id', authData.user.id)
      .single();

    if (userError || !userData) {
      throw new Error('Usuario no encontrado. Por favor regístrese nuevamente.');
    }

    return {
      id: authData.user.id,
      name: userData.name,
      email: authData.user.email!,
      role: userData.role,
      permissions: userData.permissions || []
    };
  } catch (error) {
    console.error('Error en login:', error);
    throw error;
  }
}

export async function register(data: {
  name: string;
  email: string;
  password: string;
  company: string;
}): Promise<User> {
  try {
    // Prevent registering with demo emails
    if (data.email === 'admin@demo.com' || data.email === 'vendedor@demo.com') {
      throw new Error('Este correo electrónico no está disponible');
    }

    // Create auth user
    const { data: authData, error: authError } = await supabase.auth.signUp({
      email: data.email,
      password: data.password,
      options: {
        data: {
          name: data.name
        }
      }
    });

    if (authError) {
      throw new Error('Error al registrar usuario: ' + authError.message);
    }

    if (!authData.user) {
      throw new Error('No se pudo crear el usuario');
    }

    // Create user profile
    const { error: profileError } = await supabase
      .from('users')
      .upsert([{
        id: authData.user.id,
        name: data.name,
        email: data.email,
        role: 'admin', // First user is always admin
        permissions: [{ module: 'all', actions: ['all'] }],
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }]);

    if (profileError) {
      console.error('Error al crear perfil:', profileError);
      throw new Error('Error al crear perfil de usuario: ' + profileError.message);
    }

    // Create company settings
    const { error: companyError } = await supabase
      .from('company_settings')
      .insert([{
        user_id: authData.user.id,
        name: data.company,
        rif: '',
        address: '',
        phone: '',
        email: data.email,
        default_currency: 'USD',
        taxes: [{
          id: 'iva',
          name: 'IVA',
          rate: 16,
          isDefault: true
        }],
        currencies: [{
          code: 'USD',
          name: 'Dólar Estadounidense',
          symbol: '$',
          rate: 1,
          isDefault: true
        }, {
          code: 'VES',
          name: 'Bolívar',
          symbol: 'Bs.',
          rate: 35.5,
          isDefault: false
        }]
      }]);

    if (companyError) {
      throw new Error('Error al crear configuración de empresa');
    }

    return {
      id: authData.user.id,
      name: data.name,
      email: data.email,
      role: 'admin',
      permissions: [{ module: 'all', actions: ['all'] }]
    };
  } catch (error) {
    console.error('Error en registro:', error);
    throw error;
  }
}

export async function resetPassword(email: string): Promise<void> {
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });

    if (error) {
      throw new Error('Error al enviar el correo de recuperación');
    }
  } catch (error) {
    console.error('Error en resetPassword:', error);
    throw error;
  }
}

export async function logout(): Promise<void> {
  try {
    // Clear local storage
    localStorage.clear();
    sessionStorage.clear();

    // Sign out from Supabase
    const { error } = await supabase.auth.signOut();
    if (error) throw error;

    // Force page reload to clear any persistent state
    window.location.reload();
  } catch (error) {
    console.error('Error en logout:', error);
    throw error;
  }
}