import { useAuthStore } from '../stores/auth';

export function usePermissions() {
  const { user } = useAuthStore();

  const hasPermission = (permission: string) => {
    if (!user) return false;
    
    // Admin has all permissions
    if (user.role === 'admin') return true;
    
    // Check specific permission
    return user.permissions?.some(p => 
      p.module === 'all' || p.module === permission
    );
  };

  return {
    hasPermission,
    canAccessPOS: () => hasPermission('pos.access'),
    canAccessInventory: () => hasPermission('inventory.access')
  };
}