import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreStore } from '../features/store/stores/storeStore';
import { Package, ShoppingCart, DollarSign, Megaphone, Receipt, Calculator, UserPlus } from 'lucide-react';
import { usePOSStore } from '../features/pos/stores/posStore';

export function Home() {
  const navigate = useNavigate();
  const { modules, getModuleStatus } = useStoreStore();
  const { isOpen, openCash } = usePOSStore();
  
  const getModuleIcon = (icon: string) => {
    switch (icon) {
      case 'package':
        return Package;
      case 'dollar-sign':
        return DollarSign;
      case 'megaphone':
        return Megaphone;
      default:
        return Package;
    }
  };

  const activeModules = modules.filter(module => 
    getModuleStatus(module.id) === 'free' || 
    getModuleStatus(module.id) === 'paid' || 
    getModuleStatus(module.id) === 'trial'
  );

  const handlePOSClick = () => {
    if (!isOpen) {
      openCash();
    }
    navigate('/app/pos');
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Panel de Control
        </h1>
      </div>
      
      {/* Quick Access Buttons */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <button
          onClick={handlePOSClick}
          className="flex items-center gap-3 p-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <ShoppingCart className="h-6 w-6" />
          <div className="text-left">
            <div className="font-medium">Punto de Venta</div>
            <div className="text-sm opacity-90">Procesar nueva venta</div>
          </div>
        </button>

        <button
          onClick={() => navigate('/app/pos?action=customer')}
          className="flex items-center gap-3 p-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
        >
          <UserPlus className="h-6 w-6" />
          <div className="text-left">
            <div className="font-medium">Nuevo Cliente</div>
            <div className="text-sm opacity-90">Registrar cliente</div>
          </div>
        </button>

        <button
          onClick={() => navigate('/app/pos?action=reports')}
          className="flex items-center gap-3 p-4 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          <Receipt className="h-6 w-6" />
          <div className="text-left">
            <div className="font-medium">Reportes</div>
            <div className="text-sm opacity-90">Ver ventas del día</div>
          </div>
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {activeModules.map((module) => {
          const Icon = getModuleIcon(module.icon);
          
          return (
            <div
              key={module.id}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden cursor-pointer transform transition-transform hover:scale-105"
              onClick={() => navigate(module.route)}
            >
              <div className="p-6">
                <div className="flex items-center gap-4">
                  <div className="p-3 rounded-lg bg-blue-100 dark:bg-blue-900">
                    <Icon className="h-8 w-8 text-blue-600 dark:text-blue-300" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      {module.name}
                    </h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {module.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}