import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { CompanyConfig } from '../types/company';
import { supabase } from '../../../lib/supabase';

interface CompanyState extends CompanyConfig {
  loading: boolean;
  error: string | null;
  fetchCompanyInfo: () => Promise<void>;
  updateCompanyInfo: (info: Partial<CompanyConfig>) => void;
}

const DEFAULT_CONFIG: CompanyConfig = {
  name: 'Mi Empresa',
  rif: '',
  address: '',
  phone: '',
  email: '',
  logo: undefined,
  receiptHeader: '',
  receiptFooter: '',
  defaultCurrency: 'USD',
  taxes: [
    {
      id: 'iva',
      name: 'IVA',
      rate: 16,
      isDefault: true
    }
  ],
  currencies: [
    {
      code: 'USD',
      name: 'Dólar Estadounidense',
      symbol: '$',
      rate: 1,
      isDefault: true
    },
    {
      code: 'VES',
      name: 'Bolívar',
      symbol: 'Bs.',
      rate: 35.5,
      isDefault: false
    }
  ]
};

export const useCompanyStore = create<CompanyState>()(
  persist(
    (set, get) => ({
      ...DEFAULT_CONFIG,
      loading: false,
      error: null,

      fetchCompanyInfo: async () => {
        try {
          set({ loading: true, error: null });
          
          // Check for demo user
          const demoUser = sessionStorage.getItem('demo_user');
          if (demoUser) {
            const user = JSON.parse(demoUser);
            set({
              ...DEFAULT_CONFIG,
              name: user.role === 'admin' ? 'Empresa Demo Admin' : 'Empresa Demo Vendedor',
              rif: 'J-12345678-9',
              email: user.email,
              loading: false
            });
            return;
          }
          
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) {
            set({ ...DEFAULT_CONFIG, loading: false });
            return;
          }

          let { data, error } = await supabase
            .from('company_settings')
            .select('*')
            .eq('user_id', user.id)
            .single();

          if (error) {
            // If no settings exist, create default settings
            const { data: newSettings, error: createError } = await supabase
              .from('company_settings')
              .insert([{
                user_id: user.id,
                name: DEFAULT_CONFIG.name,
                rif: DEFAULT_CONFIG.rif,
                address: DEFAULT_CONFIG.address,
                phone: DEFAULT_CONFIG.phone,
                email: DEFAULT_CONFIG.email,
                logo: DEFAULT_CONFIG.logo,
                receipt_header: DEFAULT_CONFIG.receiptHeader,
                receipt_footer: DEFAULT_CONFIG.receiptFooter,
                default_currency: DEFAULT_CONFIG.defaultCurrency,
                taxes: DEFAULT_CONFIG.taxes,
                currencies: DEFAULT_CONFIG.currencies
              }])
              .select()
              .single();

            if (createError) throw createError;
            data = newSettings;
          }

          if (data) {
            set({
              name: data.name,
              rif: data.rif || '',
              address: data.address || '',
              phone: data.phone || '',
              email: data.email || '',
              logo: data.logo || DEFAULT_CONFIG.logo,
              receiptHeader: data.receipt_header || '',
              receiptFooter: data.receipt_footer || '',
              defaultCurrency: data.default_currency || 'USD',
              taxes: data.taxes || DEFAULT_CONFIG.taxes,
              currencies: data.currencies || DEFAULT_CONFIG.currencies,
              loading: false
            });
          }
        } catch (error) {
          console.error('Error fetching company info:', error);
          set({ 
            ...DEFAULT_CONFIG,
            error: 'Error al cargar datos de la empresa',
            loading: false 
          });
        }
      },

      updateCompanyInfo: async (info) => {
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) throw new Error('No user found');

          // If role is being updated, update the user profile first
          if (info.role) {
            const { error: userError } = await supabase
              .from('users')
              .upsert({
                id: user.id,
                role: info.role,
                permissions: info.role === 'admin' ? [{ module: 'all', actions: ['all'] }] : [],
                updated_at: new Date().toISOString()
              })
              .select();

            if (userError) {
              console.error('Error updating user role:', userError);
              throw userError;
            }
          }
          // Get current state to preserve existing values
          const currentState = get();

          // Convert camelCase to snake_case for database fields
          const dbFields = {
            name: info.name,
            rif: info.rif,
            address: info.address,
            phone: info.phone,
            email: info.email,
            logo: info.logo || currentState.logo,
            receipt_header: info.receiptHeader,
            receipt_footer: info.receiptFooter,
            default_currency: info.defaultCurrency,
            taxes: info.taxes || currentState.taxes,
            currencies: info.currencies || currentState.currencies,
            updated_at: new Date().toISOString()
          };

          const { error } = await supabase
            .from('company_settings')
            .update(dbFields)
            .eq('user_id', user.id);

          if (error) throw error;

          set((state) => ({
            ...state,
            ...info,
            logo: info.logo || state.logo // Preserve logo if not being updated
          }));
        } catch (error) {
          console.error('Error updating company info:', error);
          set({ error: 'Error al actualizar datos de la empresa' });
        }
      }
    }),
    {
      name: 'company-storage',
      // Don't persist any data - always fetch from server
      partialize: () => ({})
    }
  )
);