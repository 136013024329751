import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Package, ShoppingCart, Store } from 'lucide-react';
import { usePermissions } from '../../hooks/usePermissions';
import { AppLogo } from '../common/AppLogo';
import { VERSION } from '../../config/version';

interface SidebarProps {
  onClose?: () => void;
}

export function Sidebar({ onClose }: SidebarProps) {
  const location = useLocation();
  const { canAccessPOS, canAccessInventory } = usePermissions();

  const navigation = [
    { name: 'Inicio', href: '/app', icon: Home, show: true },
    { name: 'Inventario', href: '/app/inventory', icon: Package, show: canAccessInventory() },
    { 
      name: 'Punto de Venta', 
      href: '/app/pos', 
      icon: ShoppingCart, 
      show: canAccessPOS(),
      highlight: true 
    },
    { name: 'Tienda', href: '/app/store', icon: Store, show: true }
  ].filter(item => item.show);

  return (
    <div className="flex flex-col flex-grow bg-white dark:bg-gray-800 pt-5 pb-4 overflow-y-auto">
      {/* App Logo and Version */}
      <div className="px-4 mb-6">
        <AppLogo className="h-12 w-auto mx-auto" />
        <div className="text-center text-sm text-gray-500 dark:text-gray-400 mt-2">
          Versión {VERSION.version}
        </div>
      </div>

      <nav className="mt-5 flex-1 px-2 space-y-1">
        {navigation.map((item) => {
          const Icon = item.icon;
          const isActive = location.pathname === item.href;
          
          return (
            <Link
              key={item.name}
              to={item.href}
              onClick={onClose}
              className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                isActive
                  ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
                  : item.highlight
                  ? 'text-blue-600 dark:text-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/50'
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white'
              }`}
            >
              <Icon
                className={`mr-3 h-6 w-6 ${
                  isActive 
                    ? 'text-gray-500 dark:text-gray-300' 
                    : item.highlight
                    ? 'text-blue-500 dark:text-blue-400'
                    : 'text-gray-400 dark:text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300'
                }`}
              />
              {item.name}
            </Link>
          );
        })}
      </nav>
    </div>
  );
}