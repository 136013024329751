import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Category } from '../types/product';

interface CategoryState {
  categories: Category[];
  addCategory: (category: Category) => void;
  updateCategory: (id: string, updates: Partial<Category>) => void;
  deleteCategory: (id: string) => void;
  getCategoryPath: (categoryId: string) => Category[];
  getFlattenedCategories: () => Category[];
}

export const useCategoryStore = create<CategoryState>()(
  persist(
    (set, get) => ({
      categories: [
        {
          id: '1',
          name: 'Electrónicos',
          description: 'Productos electrónicos y accesorios',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        },
        {
          id: '2',
          name: 'Alimentos',
          description: 'Productos alimenticios',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        },
        {
          id: '3',
          name: 'Ropa',
          description: 'Vestimenta y accesorios',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }
      ],

      addCategory: (category) => 
        set((state) => ({ categories: [...state.categories, category] })),

      updateCategory: (id, updates) =>
        set((state) => ({
          categories: state.categories.map(c =>
            c.id === id ? { ...c, ...updates, updatedAt: new Date().toISOString() } : c
          )
        })),

      deleteCategory: (id) =>
        set((state) => ({
          categories: state.categories.filter(c => c.id !== id)
        })),

      getCategoryPath: (categoryId) => {
        const path: Category[] = [];
        const { categories } = get();

        const findPath = (id: string): boolean => {
          const category = categories.find(c => c.id === id);
          if (!category) return false;

          path.unshift(category);
          if (category.parentId) {
            return findPath(category.parentId);
          }
          return true;
        };

        findPath(categoryId);
        return path;
      },

      getFlattenedCategories: () => {
        const { categories } = get();
        const flattened: Category[] = [];

        const flatten = (cats: Category[], level = 0) => {
          cats.forEach(category => {
            const children = categories.filter(c => c.parentId === category.id);
            flattened.push({
              ...category,
              name: '  '.repeat(level) + category.name
            });
            flatten(children, level + 1);
          });
        };

        // Start with root categories (no parent)
        flatten(categories.filter(c => !c.parentId));
        return flattened;
      }
    }),
    {
      name: 'category-storage'
    }
  )
);