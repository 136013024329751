import React from 'react';
import { useCompanyStore } from '../../features/config/stores/companyStore';

interface CompanyLogoProps {
  className?: string;
}

export function CompanyLogo({ className = 'h-8' }: CompanyLogoProps) {
  const { logo } = useCompanyStore();
  
  return (
    <img 
      src={logo || 'https://placehold.co/200x80?text=Mi+Empresa'}
      alt="Logo de la Empresa"
      className={className}
      onError={(e) => {
        e.currentTarget.src = 'https://placehold.co/200x80?text=Mi+Empresa';
      }}
    />
  );
}