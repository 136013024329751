import { createClient } from '@supabase/supabase-js';
import type { Database } from './db/types';

// Get environment variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Validate environment variables
if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error(
    'Las variables de entorno de Supabase no están configuradas.\n' +
    'Por favor:\n' +
    '1. Crea un archivo .env en la raíz del proyecto\n' +
    '2. Agrega las variables VITE_SUPABASE_URL y VITE_SUPABASE_ANON_KEY\n' +
    '3. Reinicia el servidor de desarrollo'
  );
}

// Create Supabase client
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
});