import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Warehouse } from '../types/product';

interface WarehouseState {
  warehouses: Warehouse[];
  selectedWarehouse: string | null;
  
  addWarehouse: (warehouse: Warehouse) => void;
  updateWarehouse: (id: string, updates: Partial<Warehouse>) => void;
  deleteWarehouse: (id: string) => void;
  setSelectedWarehouse: (id: string | null) => void;
}

export const useWarehouseStore = create<WarehouseState>()(
  persist(
    (set) => ({
      warehouses: [
        {
          id: 'default',
          name: 'Tienda Principal',
          type: 'store', // Cambiado a store para que funcione como punto de venta
          location: 'Principal',
          isDefault: true,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }
      ],
      selectedWarehouse: 'default',

      addWarehouse: (warehouse) => 
        set((state) => ({ warehouses: [...state.warehouses, warehouse] })),

      updateWarehouse: (id, updates) =>
        set((state) => ({
          warehouses: state.warehouses.map(w =>
            w.id === id ? { ...w, ...updates, updatedAt: new Date().toISOString() } : w
          )
        })),

      deleteWarehouse: (id) =>
        set((state) => ({
          warehouses: state.warehouses.filter(w => w.id !== id)
        })),

      setSelectedWarehouse: (id) => set({ selectedWarehouse: id })
    }),
    {
      name: 'warehouse-storage',
      partialize: (state) => ({
        warehouses: state.warehouses,
        selectedWarehouse: state.selectedWarehouse
      })
    }
  )
);