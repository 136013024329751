import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShoppingCart, Package, DollarSign, Receipt } from 'lucide-react';
import { ProductSearch } from './ProductSearch';
import { useSearchParams } from 'react-router-dom';
import { CartPanel } from './CartPanel';
import { PaymentModal } from './PaymentModal';
import { CustomerSelect } from './CustomerSelect';
import { CashCloseDialog } from './CashCloseDialog';
import { CashRegisterDialog } from './CashRegisterDialog';
import { useInventoryStore } from '../../inventory/stores/inventoryStore';
import { useConfigStore } from '../../config/stores/configStore';
import { useAuthStore } from '../../../stores/auth';
import { usePOSStore } from '../stores/posStore';
import { Sale, Customer } from '../types';

export function POSDashboard() {
  const navigate = useNavigate();
  const { selectedWarehouse } = useInventoryStore();
  const { taxes } = useConfigStore();
  const { user } = useAuthStore();
  const { isOpen, closeCash } = usePOSStore();
  
  const [showPayment, setShowPayment] = useState(false);
  const [cart, setCart] = useState<any[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [showCashDialog, setShowCashDialog] = useState(false);
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');

  useEffect(() => {
    if (action === 'customer') {
      setShowCustomerForm(true);
    }
  }, [action]);

  // Verificar permisos
  const hasAccess = user?.role === 'admin' || user?.permissions?.some(p => 
    p.module === 'all' || p.module === 'pos.access'
  );

  if (!hasAccess) {
    return (
      <div className="h-[calc(100vh-4rem)] flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            No tiene permisos para acceder al punto de venta
          </h2>
        </div>
      </div>
    );
  }

  // Si no hay sesión, mostrar botón de apertura
  if (!isOpen) {
    return (
      <div className="h-[calc(100vh-4rem)] flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            No hay una sesión de caja abierta
          </h2>
          <button
            onClick={() => setShowCashDialog(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Abrir Caja
          </button>
        </div>

        {showCashDialog && (
          <CashRegisterDialog onClose={() => setShowCashDialog(false)} />
        )}
      </div>
    );
  }

  // Calcular totales
  const subtotal = cart.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const taxAmount = subtotal * (taxes[0]?.rate || 16) / 100;
  const total = subtotal + taxAmount;

  return (
    <div className="h-[calc(100vh-4rem)] flex">
      {/* Panel Principal */}
      <div className="flex-1 flex flex-col p-4 overflow-hidden">
        {/* Cliente y Búsqueda */}
        <div className="space-y-4 mb-4">
          <CustomerSelect onCustomerSelect={setSelectedCustomer} />
          <ProductSearch onProductSelect={(product) => {
            setCart([...cart, {
              productId: product.id,
              name: product.name,
              quantity: 1,
              price: product.baseUnit.price.USD
            }]);
          }} />
        </div>

        {/* Lista de Productos */}
        <div className="flex-1 bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">
              Productos en Carrito
            </h2>
          </div>
          <div className="p-4 overflow-auto">
            {cart.length === 0 ? (
              <p className="text-center text-gray-500">
                No hay productos en el carrito
              </p>
            ) : (
              <div className="space-y-4">
                {cart.map((item, index) => (
                  <div key={index} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">
                        {item.name}
                      </h3>
                      <p className="text-sm text-gray-500">
                        ${item.price} x {item.quantity}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <button
                        onClick={() => {
                          const newCart = [...cart];
                          if (item.quantity > 1) {
                            newCart[index].quantity--;
                          } else {
                            newCart.splice(index, 1);
                          }
                          setCart(newCart);
                        }}
                        className="text-red-600 hover:text-red-800"
                      >
                        -
                      </button>
                      <span className="text-gray-900">{item.quantity}</span>
                      <button
                        onClick={() => {
                          const newCart = [...cart];
                          newCart[index].quantity++;
                          setCart(newCart);
                        }}
                        className="text-green-600 hover:text-green-800"
                      >
                        +
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Panel de Carrito */}
      <CartPanel
        items={cart}
        onUpdateQuantity={(productId, quantity) => {
          setCart(cart.map(item =>
            item.productId === productId
              ? { ...item, quantity }
              : item
          ));
        }}
        onRemoveItem={(productId) => {
          setCart(cart.filter(item => item.productId !== productId));
        }}
        onCheckout={() => setShowPayment(true)}
        total={total}
        subtotal={subtotal}
        taxAmount={taxAmount}
      />

      {/* Modal de Pago */}
      {showPayment && (
        <PaymentModal
          total={total}
          customerId={selectedCustomer?.id || ''}
          customerBalance={0}
          onClose={() => setShowPayment(false)}
          onComplete={() => {
            setCart([]);
            setShowPayment(false);
          }}
        />
      )}
    </div>
  );
}