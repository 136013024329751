import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from '../lib/supabase';

export interface UserPermission {
  module: string;
  actions: string[];
  warehouses?: string[];
  terminals?: string[];
}

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  permissions: UserPermission[];
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  login: (user: User) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      login: async (user) => {
        // For demo users, store token in localStorage
        if (user.email.endsWith('@demo.com')) {
          localStorage.setItem('sb-token', 'demo-token');
        }
        
        set({ user, isAuthenticated: true });
      },
      logout: async () => {
        // Clear all storage
        localStorage.clear();
        sessionStorage.clear();

        // Sign out from Supabase if not a demo user
        const demoUser = localStorage.getItem('demo_user');
        if (!demoUser) {
          try {
            await supabase.auth.signOut();
          } catch (error) {
            console.error('Error signing out:', error);
          }
        }

        set({ user: null, isAuthenticated: false });
        
        // Force reload to clear any persistent state
        window.location.href = '/login';
      },
    }),
    {
      name: 'auth-storage',
      // Only persist user data
      partialize: (state) => ({ 
        user: state.user,
        isAuthenticated: state.isAuthenticated 
      })
    }
  )
);