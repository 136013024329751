import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Module, License, Payment } from '../types';
import { MODULES } from '../config/modules';

interface StoreState {
  modules: Module[];
  licenses: License[];
  payments: Payment[];
  
  addLicense: (license: License) => void;
  updateLicense: (id: string, updates: Partial<License>) => void;
  addPayment: (payment: Payment) => void;
  updatePayment: (id: string, updates: Partial<Payment>) => void;
  getModuleStatus: (moduleId: string) => 'free' | 'paid' | 'trial' | 'inactive';
  startTrial: (moduleId: string) => void;
}

export const useStoreStore = create<StoreState>()(
  persist(
    (set, get) => ({
      modules: [
        {
          id: 'inventory',
          name: 'Inventario y POS',
          description: 'Gestión de inventario y punto de venta',
          icon: 'package',
          price: 0,
          status: 'free',
          features: [
            'Control de stock',
            'Punto de venta',
            'Impresión fiscal',
            'Múltiples almacenes',
            'Reportes básicos'
          ],
          route: '/app/inventory'
        },
        {
          id: 'accounting',
          name: 'Contabilidad',
          description: 'Sistema contable completo con libro de IVA',
          icon: 'dollar-sign',
          price: 35,
          status: 'inactive',
          features: [
            'Libro de IVA',
            'Plan de cuentas',
            'Balance general',
            'Estado de resultados',
            'Reportes fiscales'
          ],
          route: '/app/accounting'
        },
        {
          id: 'marketing',
          name: 'Marketing',
          description: 'Gestión de promociones y campañas',
          icon: 'megaphone',
          price: 65,
          status: 'inactive',
          features: [
            'Campañas por email',
            'Promociones',
            'Descuentos', 
            'Fidelización',
            'Análisis de ventas'
          ],
          route: '/app/marketing'
        }
      ],
      licenses: [],
      payments: [],

      addLicense: (license) =>
        set((state) => ({ licenses: [...state.licenses, license] })),

      updateLicense: (id, updates) =>
        set((state) => ({
          licenses: state.licenses.map(l =>
            l.id === id ? { ...l, ...updates, updatedAt: new Date().toISOString() } : l
          )
        })),

      addPayment: (payment) =>
        set((state) => ({ payments: [...state.payments, payment] })),

      updatePayment: (id, updates) =>
        set((state) => ({
          payments: state.payments.map(p =>
            p.id === id ? { ...p, ...updates, updatedAt: new Date().toISOString() } : p
          )
        })),

      getModuleStatus: (moduleId) => {
        const module = get().modules.find(m => m.id === moduleId);
        if (!module) return 'inactive';
        
        // Core modules are free
        if (module.price === 0) return 'free';
        
        const { licenses } = get();
        const license = licenses.find(l => 
          l.moduleId === moduleId && 
          l.status === 'active' &&
          (!l.expirationDate || new Date(l.expirationDate) > new Date())
        );

        if (!license) return 'inactive';
        return license.paymentId === 'trial' ? 'trial' : 'paid';
      },

      startTrial: (moduleId) => {
        const now = new Date();
        const expirationDate = new Date();
        expirationDate.setMonth(now.getMonth() + 1);

        const license: License = {
          id: crypto.randomUUID(),
          moduleId,
          userId: 'current-user-id',
          paymentId: 'trial',
          status: 'active',
          activationDate: now.toISOString(),
          expirationDate: expirationDate.toISOString(),
          createdAt: now.toISOString(),
          updatedAt: now.toISOString()
        };

        get().addLicense(license);
      }
    }),
    {
      name: 'store-storage'
    }
  )
);