import React from 'react';
import { usePOSStore } from '../stores/posStore';
import { AlertCircle } from 'lucide-react';

interface CashRegisterDialogProps {
  onClose: () => void;
}

export function CashRegisterDialog({ onClose }: CashRegisterDialogProps) {
  const { openCash } = usePOSStore();

  const handleOpenCash = () => {
    openCash();
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">
            Abrir Caja
          </h2>
        </div>

        <div className="p-6">
          <p className="text-gray-600 mb-6">
            ¿Está seguro que desea abrir la caja?
          </p>

          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancelar
            </button>
            <button
              onClick={handleOpenCash}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
            >
              Abrir Caja
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}