import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Sale, Customer, Payment } from '../types';
import { supabase } from '../../../lib/supabase';

interface POSState {
  isOpen: boolean;
  sales: Sale[];
  loading: boolean;
  error: string | null;
  currentSession?: {
    openedAt: string;
    initialCash: number;
    finalCash?: number;
    closedAt?: string;
    notes?: string;
  };
  
  openCash: () => void;
  closeCash: (data: { finalCash: number; notes?: string; closedAt: string }) => void;
  createSale: (sale: Omit<Sale, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  getSales: () => Promise<void>;
}

export const usePOSStore = create<POSState>()(
  persist(
    (set) => ({
      isOpen: false,
      sales: [],
      loading: false,
      error: null,
      currentSession: undefined,
      
      openCash: () => {
        set({ 
          isOpen: true,
          currentSession: {
            openedAt: new Date().toISOString(),
            initialCash: 0
          }
        });
      },
      
      closeCash: (data) => {
        set((state) => ({
          isOpen: false,
          currentSession: state.currentSession ? {
            ...state.currentSession,
            ...data
          } : undefined
        }));
      },
      
      createSale: async (saleData) => {
        set({ loading: true, error: null });
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) throw new Error('Usuario no autenticado');

          const now = new Date().toISOString();
          const sale = {
            ...saleData,
            id: crypto.randomUUID(),
            user_id: user.id,
            created_at: now,
            updated_at: now
          };
          
          const { error } = await supabase
            .from('sales')
            .insert(sale);

          if (error) throw error;
          
          set((state) => ({
            sales: [...state.sales, sale as Sale],
            loading: false
          }));
        } catch (error) {
          console.error('Error al crear venta:', error);
          set({ error: 'Error al crear la venta', loading: false });
          throw error;
        }
      },
      
      getSales: async () => {
        set({ loading: true, error: null });
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) throw new Error('Usuario no autenticado');

          const { data, error } = await supabase
            .from('sales')
            .select('*')
            .eq('user_id', user.id)
            .order('created_at', { ascending: false });

          if (error) throw error;
          
          set({ 
            sales: data || [], 
            loading: false 
          });
        } catch (error) {
          console.error('Error al cargar ventas:', error);
          set({ error: 'Error al cargar las ventas', loading: false });
          throw error;
        }
      }
    }),
    {
      name: 'pos-storage'
    }
  )
);