import { create } from 'zustand';
import { createClient } from '@supabase/supabase-js';
import type { Product, Category, Warehouse, StockMovement } from '../types';

const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
);

interface InventoryState {
  products: Product[];
  categories: Category[];
  warehouses: Warehouse[];
  loading: boolean;
  error: string | null;
  selectedWarehouse: string | null;
  
  fetchProducts: () => Promise<void>;
  fetchCategories: () => Promise<void>;
  fetchWarehouses: () => Promise<void>;
  addProduct: (product: Product) => Promise<void>;
  updateProduct: (id: string, product: Partial<Product>) => Promise<void>;
  deleteProduct: (id: string) => Promise<void>;
  updateStock: (movement: StockMovement) => void;
  setSelectedWarehouse: (id: string | null) => void;
}

export const useInventoryStore = create<InventoryState>((set, get) => ({
  products: [],
  categories: [],
  warehouses: [],
  loading: false,
  error: null,
  selectedWarehouse: null,

  fetchProducts: async () => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No se encontró el usuario');

      const { data, error } = await supabase
        .from('products')
        .select('*')
        .eq('user_id', user.id)
        .order('name');

      if (error) throw error;
      set({ products: data || [], loading: false });
    } catch (error) {
      console.error('Error fetching products:', error);
      set({ 
        error: error instanceof Error ? error.message : 'Error al cargar productos',
        loading: false 
      });
    }
  },

  fetchCategories: async () => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No se encontró el usuario');

      const { data, error } = await supabase
        .from('categories')
        .select('*')
        .eq('user_id', user.id)
        .order('name');

      if (error) throw error;
      set({ categories: data || [], loading: false });
    } catch (error) {
      console.error('Error fetching categories:', error);
      set({ 
        error: error instanceof Error ? error.message : 'Error al cargar categorías',
        loading: false 
      });
    }
  },

  fetchWarehouses: async () => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No se encontró el usuario');

      const { data, error } = await supabase
        .from('warehouses')
        .select('*')
        .eq('user_id', user.id)
        .order('name');

      if (error) throw error;
      set({ warehouses: data || [], loading: false });
    } catch (error) {
      console.error('Error fetching warehouses:', error);
      set({ 
        error: error instanceof Error ? error.message : 'Error al cargar almacenes',
        loading: false 
      });
    }
  },

  addProduct: async (product) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No se encontró el usuario');

      // Prepare product data
      const productData = {
        user_id: user.id,
        sku: product.sku,
        name: product.name,
        description: product.description,
        category_id: product.categoryId ? product.categoryId : null,
        brand: product.brand,
        model: product.model,
        type: product.type,
        status: product.status,
        min_stock: product.minStock,
        max_stock: product.maxStock,
        track_lots: product.trackLots,
        requires_expiration: product.requiresExpiration,
        base_unit: product.baseUnit,
        units: product.units || [],
        main_image: product.mainImage,
        gallery: product.gallery || [],
        stock: product.stock || {}
      };

      // Insert product
      const { data, error } = await supabase
        .from('products')
        .insert(productData)
        .select()
        .single();

      if (error) throw error;

      // Refresh products list
      await get().fetchProducts();
      set({ loading: false });

    } catch (error) {
      console.error('Error creating product:', error);
      let errorMessage = 'Error al crear producto';
      
      if (error instanceof Error) {
        // Handle specific error cases
        if (error.message.includes('products_category_user_match')) {
          errorMessage = 'La categoría seleccionada no es válida';
        } else if (error.message.includes('products_sku_key')) {
          errorMessage = 'El SKU ya existe';
        } else {
          errorMessage = error.message;
        }
      }
      
      set({ 
        error: errorMessage,
        loading: false 
      });
      throw error;
    }
  },

  updateProduct: async (id, product) => {
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');
      // Validate category exists and belongs to user if being updated
      if (updates.categoryId) {
        const { data: category, error: categoryError } = await supabase
          .from('categories')
          .select('id')
          .eq('id', updates.categoryId)
          .eq('user_id', user.id)
          .single();

        if (categoryError || !category) {
          throw new Error('La categoría seleccionada no es válida');
        }
      }

      const { data, error } = await supabase
        .from('products')
        .update({
          ...updates,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .eq('user_id', user.id)
        .select()
        .single();

      if (error) throw error;

      // Update state
      set((state) => ({
        products: state.products.map(p => p.id === id ? data : p),
        loading: false,
        error: null
      }));

    } catch (error) {
      console.error('Error updating product:', error);
      set({ 
        error: error instanceof Error ? error.message : 'Error al actualizar producto',
        loading: false 
      });
      throw error;
    }
  },

  deleteProduct: async (id) => {
    set({ loading: true, error: null });
    try {
      await inventoryApi.deleteProduct(id);
      get().fetchProducts();
    } catch (error) {
      set({ error: 'Failed to delete product', loading: false });
    }
  },

  updateStock: (movement) => {
    set((state) => {
      const updatedProducts = state.products.map(product => {
        if (product.id === movement.productId) {
          const newStock = { ...product.stock };
          
          // Reducir stock en almacén origen si es una transferencia
          if (movement.fromWarehouseId) {
            movement.items.forEach(item => {
              if (!newStock[movement.fromWarehouseId]) {
                newStock[movement.fromWarehouseId] = {};
              }
              const currentStock = newStock[movement.fromWarehouseId][item.unitId] || 0;
              newStock[movement.fromWarehouseId][item.unitId] = currentStock - item.quantity;
            });
          }
          
          // Aumentar stock en almacén destino
          movement.items.forEach(item => {
            if (!newStock[movement.toWarehouseId]) {
              newStock[movement.toWarehouseId] = {};
            }
            const currentStock = newStock[movement.toWarehouseId][item.unitId] || 0;
            newStock[movement.toWarehouseId][item.unitId] = currentStock + item.quantity;
          });
          
          return { ...product, stock: newStock };
        }
        return product;
      });

      return { products: updatedProducts };
    });
  },

  setSelectedWarehouse: (id) => {
    set({ selectedWarehouse: id });
  }
}));