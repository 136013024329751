import React, { useState } from 'react';
import { useWarehouseStore } from '../stores/warehouseStore';
import { Building2, Plus, Edit2, Trash2 } from 'lucide-react';
import { Warehouse } from '../types/product';
import { generateUUID } from '../../../lib/utils/uuid';

export function WarehouseManager() {
  const { warehouses, addWarehouse, updateWarehouse, deleteWarehouse } = useWarehouseStore();
  const [showForm, setShowForm] = useState(false);
  const [editingWarehouse, setEditingWarehouse] = useState<Warehouse | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    type: 'warehouse' as const,
    location: '',
    isDefault: false
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const warehouseData: Warehouse = {
      id: editingWarehouse?.id || generateUUID(),
      ...formData,
      createdAt: editingWarehouse?.createdAt || new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    if (editingWarehouse) {
      updateWarehouse(editingWarehouse.id, warehouseData);
    } else {
      addWarehouse(warehouseData);
    }

    setShowForm(false);
    setEditingWarehouse(null);
    setFormData({ name: '', type: 'warehouse', location: '', isDefault: false });
  };

  return (
    <div className="bg-white shadow rounded-lg">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-medium text-gray-900 flex items-center gap-2">
            <Building2 className="h-5 w-5" />
            Almacenes
          </h2>
          <button
            onClick={() => setShowForm(true)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Plus className="h-4 w-4" />
            Nuevo Almacén
          </button>
        </div>
      </div>

      <div className="divide-y divide-gray-200">
        {warehouses.map((warehouse) => (
          <div key={warehouse.id} className="p-6">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-sm font-medium text-gray-900">
                  {warehouse.name}
                  {warehouse.isDefault && (
                    <span className="ml-2 px-2 py-1 text-xs font-medium rounded-full bg-green-100 text-green-800">
                      Principal
                    </span>
                  )}
                </h3>
                <p className="text-sm text-gray-500">
                  {warehouse.type === 'store' ? 'Tienda' : 'Almacén'} - {warehouse.location}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => {
                    setEditingWarehouse(warehouse);
                    setFormData({
                      name: warehouse.name,
                      type: warehouse.type,
                      location: warehouse.location,
                      isDefault: warehouse.isDefault
                    });
                    setShowForm(true);
                  }}
                  className="text-blue-600 hover:text-blue-900"
                >
                  <Edit2 className="h-4 w-4" />
                </button>
                <button
                  onClick={() => deleteWarehouse(warehouse.id)}
                  className="text-red-600 hover:text-red-900"
                  disabled={warehouse.isDefault}
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showForm && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full">
            <form onSubmit={handleSubmit} className="p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                {editingWarehouse ? 'Editar Almacén' : 'Nuevo Almacén'}
              </h3>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Nombre
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Tipo
                  </label>
                  <select
                    value={formData.type}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value as 'store' | 'warehouse' })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  >
                    <option value="warehouse">Almacén</option>
                    <option value="store">Tienda</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Ubicación
                  </label>
                  <input
                    type="text"
                    value={formData.location}
                    onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.isDefault}
                    onChange={(e) => setFormData({ ...formData, isDefault: e.target.checked })}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    disabled={editingWarehouse?.isDefault}
                  />
                  <label className="ml-2 block text-sm text-gray-900">
                    Almacén principal
                  </label>
                </div>
              </div>

              <div className="mt-6 flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => {
                    setShowForm(false);
                    setEditingWarehouse(null);
                  }}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                >
                  {editingWarehouse ? 'Guardar Cambios' : 'Crear Almacén'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}