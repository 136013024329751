import React from 'react';

interface AppLogoProps {
  className?: string;
}

export function AppLogo({ className = 'h-8' }: AppLogoProps) {
  return (
    <img 
      src="https://provalnet.net/wp-content/uploads/2024/11/logo.png"
      alt="Axiloop"
      className={className}
      onError={(e) => {
        e.currentTarget.src = 'https://placehold.co/200x80?text=Axiloop';
      }}
    />
  );
}