import React, { useState, useEffect } from 'react';
import { useCompanyStore } from '../stores/companyStore';
import { Building2, Save } from 'lucide-react';
import { ImageUploader } from '../../inventory/components/ImageUploader';
import type { CompanyConfig } from '../types/company';

export function CompanyForm() {
  const config = useCompanyStore();
  const [formData, setFormData] = useState<CompanyConfig>({
    name: config.name,
    rif: config.rif,
    address: config.address,
    phone: config.phone,
    email: config.email,
    logo: config.logo,
    receiptHeader: config.receiptHeader,
    receiptFooter: config.receiptFooter,
    defaultCurrency: config.defaultCurrency,
    taxes: config.taxes,
    currencies: config.currencies
  });

  // Update form when store changes
  useEffect(() => {
    setFormData({
      name: config.name,
      rif: config.rif,
      address: config.address,
      phone: config.phone,
      email: config.email,
      logo: config.logo,
      receiptHeader: config.receiptHeader,
      receiptFooter: config.receiptFooter,
      defaultCurrency: config.defaultCurrency,
      taxes: config.taxes,
      currencies: config.currencies
    });
  }, [config]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    config.updateCompanyInfo(formData);
  };

  const handleImageUpload = (imageData: { url: string }) => {
    setFormData({ ...formData, logo: imageData.url });
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-900 dark:text-white flex items-center gap-2 mb-4">
        <Building2 className="h-5 w-5" />
        Datos de la Empresa
      </h2>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Nombre de la Empresa
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              RIF
            </label>
            <input
              type="text"
              value={formData.rif}
              onChange={(e) => setFormData({ ...formData, rif: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div className="sm:col-span-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Dirección
            </label>
            <textarea
              value={formData.address}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Teléfono
            </label>
            <input
              type="tel"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Correo Electrónico
            </label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="sm:col-span-2">
            <ImageUploader
              label="Logo de la Empresa"
              currentImage={formData.logo}
              onUpload={handleImageUpload}
              onClear={() => setFormData({ ...formData, logo: undefined })}
            />
          </div>

          <div className="sm:col-span-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Encabezado de Factura
            </label>
            <textarea
              value={formData.receiptHeader}
              onChange={(e) => setFormData({ ...formData, receiptHeader: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="sm:col-span-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Pie de Factura
            </label>
            <textarea
              value={formData.receiptFooter}
              onChange={(e) => setFormData({ ...formData, receiptFooter: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="flex items-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Save className="h-4 w-4" />
            Guardar Cambios
          </button>
        </div>
      </form>
    </div>
  );
}